.Categoriesliste {
  display:flex;
  grid-template-columns: auto;
}


.categorie {
  background-color: lightgrey;
  padding: 5px;
  border-radius: 3px;
  margin-right: 4px;
  font-size: 0.8em;
}

.categorie i {
  color:orange;
}
