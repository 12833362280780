

.rating i:before {
  font-size: 1.5rem;
  color:orange;
}

.swd_r_row   .swd_r_rating .swd_edit_comment {
  display: none;
}
