

article.Recepie.edit {
  display:grid;
  grid-template-columns: 60% 40%;
}
article.Recepie {
  display:grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "zutaten"
    "zubereitung";

}

article.Recepie section {
  grid-column-start: 1;
  grid-row-start: 1;
  padding:1rem;
  display:grid;
  grid-template-columns: 100%;
  grid-template-areas:
    "nav"
    "headline"
    "description"
    "duration"
    "zutaten"
    "zutatenliste"
    "geraeteliste"
    "zubereitung";

}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: lightgrey;
  border-color:lightgrey;
}

div#headline {
  grid-area: headline;
  border-bottom: 1px solid #443857
;
  margin-bottom: 2em;
}
div#zutaten {
    grid-area: zutaten
}

div#zubereitung {
    grid-area: zubereitung
}
div#duration {
  grid-area: duration
}

div#description {
  grid-area: description
}
div.Zutatenliste {
  grid-area: zutatenliste
}
div.Geraeteliste {
  grid-area: geraeteliste
}


nav {
  grid-area: nav
}
i#edit_recepie {
    text-align: right;
    color:lightgrey;
    margin-left: 2em;
    font-size: 0.6em;
}

article.Recepie.edit section {
  grid-column-start: 2;
  grid-row-start: 1;
  padding:3rem;
  display:block;
}

article.Recepie aside {
  grid-column-start: 1;
  grid-row-start:1;
  background-color: #cccccc;
  padding:1rem;

}
article.Recepie.edit aside{
  grid-column-start: 1  ;
  grid-row-start:1;
  background-color: #cccccc;
  padding:1rem;

}
.Zutaten {
  width:90%
}

aside .form-group {
  display:grid;
  grid-template-columns: 40% 60%;
}

i:before {
  margin-right: 2px;
}

.ingredient-row {
  display:grid;
  grid-template-columns: 20% 15% auto;
  padding: 2px;
  border-bottom: 1px dotted grey;
}

.ingredient-col-1-2 {
  grid-column-start: 1;
  grid-column-end: 3;
  justify-self: center;
}
.ingredient-row:first-child {
  display:block;
  background-color: #443857
;
  border-radius: 10px 10px 0px 0px;
  color: white;
  font-weight: bold;
  text-align: center;


}

.ingredient-row:last-child {
  border-bottom: 0px dotted grey;
}
.ingredient-row .ingredient-col-1 {
  text-align: right;
  margin-right: 5px;
}


.Zutatenliste, .Geraeteliste {
  border-radius: 10px;
  border: 1px solid #443857
;
  margin-bottom: 2rem;
}

.geraet-row {
  margin-left: 0px;
  padding: 7px;
  border-bottom: 1px dotted grey;

}

.geraet-row:first-child {
  background-color: #443857
;
  border-radius: 10px 10px 0px 0px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-left: 0px;
  padding: 7px;
  border-bottom: 1px dotted grey;

}

.geraet-row:last-child {

  border-bottom: 0px dotted grey;

}

.smallWidth {
  max-width: 10rem;
}
.step-row {
  border-bottom: 1px solid #443857
;
  margin-bottom: 2rem;
}

.step-row h3 {
  font-size: 1.2em;
  font-weight: bold;
}
.swd_share i {
  color: #443857;
  font-size: 2.5rem;
}

@media (min-width: 800px) {

  article.Recepie section {
    padding:1rem;
    grid-template-columns: 400px auto;
    grid-template-areas:
      "headline headline"
      "nav ."
      "description description"
      "duration ."
      "zutaten zubereitung"
      "zutatenliste zubereitung"
      "geraeteliste zubereitung"
      ;

  }
  div#zubereitung {
      margin-left: 10%;
      max-width:800px
  }

}


@media print {
  @page {
    size: auto;
    margin: 1.8cm 1.5cm 1.8cm 1.5cm;
    orphans: 4;
    widows: 2;
  }

  .ingredient-row,
  .geraet-row{
    font-size: 1.3rem;
  }
  .step-row {
    page-break-after : avoid;
    border-bottom:none;
  }

  #edit_recepie,
  footer,
  nav,
  .swd_share {
    display: none !important;
  }
  div#root{
    font-size: 1.5rem;
  }

  .swd_usr_1:before,
  .swd_usr_2:before,
  .swd_usr_3:before,
  .swd_usr_4:before,
  .swd_usr_5:before,
  .swd_usr_6:before,
  .swd_usr_7:before,
  .swd_usr_8:before,
  .swd_usr_9:before,
  .swd_usr_10:before,
  .swd_usr_11:before,
  .swd_usr_12:before,
  .swd_usr_13:before,
  .swd_usr_14:before,
  .swd_usr_15:before,
  .swd_usr_16:before,
  .swd_usr_17:before,
  .swd_usr_18:before,
  .swd_usr_19:before,
  .swd_usr_20:before,
  .swd_usr_21:before,
  .swd_usr_22:before,
  .swd_usr_23:before,
  .swd_usr_24:before,
  .swd_usr_25:before,
  .swd_usr_26:before,
  .swd_usr_27:before {
    display:none;
  }

  header,
  .rating,
  .nutzer,
  #calculatePersons,
  .categories, .difficulty {
    display:none;
  }

  div.duration details {
    display:none;
  }
  div#headline {
    border-bottom: 0px solid;
    margin-bottom: 0.5rem;
  }
  div.duration {
    border-bottom: 1px solid black;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
  }

  .oneColumn section div#description {
    border-bottom: 1px solid black;
    margin-bottom: 2rem;
    padding-bottom: 0.5rem;
  }

  div#headline,
  div#description,
  div.duration {
    text-align: center;
  }

  article.Recepie section {
    padding:1rem;
    min-height: 0;
    grid-template-columns: 370px auto;
    grid-template-areas:
      "headline headline"
      "description description"
      "duration duration"
      "zutaten zubereitung"
      ;

  }

  article.oneColumn {
    display:grid;
    grid-template-columns: 100%;
    grid-template-areas:
    "zubereitung";

  }

  article.Recepie.oneColumn section {
    padding: 1rem;
    min-height: 0;
    grid-template-columns: auto;
    grid-template-areas:
      "headline"
      "description"
      "zubereitung";
  }
  article.Recepie.oneColumn div#zubereitung {
    margin-right: 0px;
    margin-left: 0px;
    max-width: 100%;
  }
  .Zubereitungliste {
    padding-left: 2em !important;
  }
  div#zubereitung {
    margin-right: 0px;
    margin-left: 5%;
    max-width: 15cm;
  }

  .ingredient-row:first-child,
  .geraet-row:first-child {
    background-color: #0d6d19
  }
  .step-row h3 {
    border-bottom: 1px solid #0d6d19;
  }

  .Zutatenliste, .Geraeteliste {
    border: 1px solid #443857;

  }

}
