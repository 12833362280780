.swd_r_row {

  padding-bottom: 15px;
  padding-left: 10px;
  margin-bottom: 20px;
  border-bottom: 1px dotted grey;
  display:grid;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    "title title"
    "duration rating" ;
}

.swd_r_title {
  font-size: 1.5em;
  color: rgba(0, 0, 0, 0.8);
  grid-area:  title
}

.swd_r_rating {
  grid-area: rating;
  font-size: 0.5em;
}

.swd_r_duration {
  grid-area: duration
}


.swd_r_desc {
  display:none;
  grid-area: desc
}

.duration details {
  display: block;

}
.item-list .duration details {
  display: none;

}

.swd_icon {
  color :#44385799;
  padding-right: 0.5rem;
}
.swd_usr_1:before {
  content:"\f327";
}

.swd_usr_2:before {
  content:"\f6fc";
}

.swd_usr_3:before {
  content:"\f74f";
}

.swd_usr_4:before {
  content:"\f6c8";
}

.swd_usr_5:before {
  content:"\f4d8";
}

.swd_usr_17:before {
  content:"\f6e4";
}

.swd_usr_18:before {
  content:"\f44b";
}

.swd_usr_19:before {
  content:"\f185";
}

.swd_usr_20:before {
  content:"\f7f0";
}
.swd_usr_24:before {
  content:"\f702";
}

.swd_usr_25:before {
  content:"\f39a";
}

.swd_usr_27:before {
  content:"\e2e7";
}

/*
4	Kühbach
3	Schwandorf
1	Fürth
2	Kempten
0	Anonym
17	Schwabach
18	Sulzbürg
5	Markel
19	Wenzenburger
24 Mareike
*/

@media (min-width: 800px) {
  .swd_r_row {
    border-radius: 5px;
    background-color:white;
    padding-bottom: 15px;
    padding-left: 0px;
    margin: 10px;
    min-height: 200px;
    border-bottom: 1px dotted grey;
    display:grid;
    grid-template-areas:
      "title title"
      "desc desc"
      "duration rating";
  }
  .duration details {
    display: block;

  }

i.fa-file {
  display:none;
}

  .swd_r_title {
    text-align: center;
    align-self: center;
    font-size: 1.2em;
    border-bottom: 1px solid lightgrey;

  }
  .swd_r_desc {
    padding-left: 15px;
    display:block;
    grid-area: desc;
    max-height: 5rem;
    overflow: hidden;

  }
  .swd_r_duration,
  .swd_r_rating {
    padding-left:10px;
    align-self: end;
  }
  .swd_r_duration .duration {
    font-size: 0.8rem;
  }
  .swd_r_duration .duration:before {
    content:"\f017";
    font-family: 'Font Awesome 6 Pro';
    font-weight: 400;
    color: grey;
    margin-right: 5px;
  }
  .swd_r_rating .rating i:before{
    font-size: 1.0rem;
  }

  .recepie.item-list {
    display:grid;
    grid-template-columns: 33% 33% 33%;

  }
  .Landing {
    background-color: #4b4974;
  }

}

@media (min-width: 1050px) {

  .recepie.item-list {
    display:grid;
    grid-template-columns: 25% 25% 25% 25%;

  }


}
