table.swd-admin {
  margin: 1rem 3rem 1rem 3rem;
  width: 80%;
}

.swd-admin thead {
  background-color: #443857;
  border-radius: 0px 5px 5px 0px;
  color:white;
}

.swd-admin tr:nth-child(even){
  background-color: lightgrey
}
.swd-admin td{
  padding: 5px;
}
