

@font-face {
  font-family: 'merloround_medium';
  src: url('./style/fonts/merloround_medium_macroman/merlo-roundmedium-webfont.eot'), url('./style/fonts/merloround_medium_macroman/merlo-roundmedium-webfont.eot?#iefix') format('embedded-opentype'), url('./style/fonts/merloround_medium_macroman/merlo-roundmedium-webfont.woff2') format('woff2'), url('./style/fonts/merloround_medium_macroman/merlo-roundmedium-webfont.woff') format('woff'), url('./style/fonts/merloround_medium_macroman/merlo-roundmedium-webfont.ttf') format('truetype'), url('./style/fonts/merloround_medium_macroman/merlo-roundmedium-webfont.svg#merloround_medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'merloround_bold';
  src: url('./style/fonts/merloround_bold_macroman/merlo-roundbold-webfont.eot'), url('./style/fonts/merloround_bold_macroman/merlo-roundbold-webfont.eot?#iefix') format('embedded-opentype'), url('./style/fonts/merloround_bold_macroman/merlo-roundbold-webfont.woff2') format('woff2'), url('./style/fonts/merloround_bold_macroman/merlo-roundbold-webfont.woff') format('woff'), url('./style/fonts/merloround_bold_macroman/merlo-roundbold-webfont.ttf') format('truetype'), url('./style/fonts/merloround_bold_macroman/merlo-roundbold-webfont.svg#merloround_bold') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
    font-family: Pfeffer;
    src: url("./style/fonts/PfefferMediaeval.otf") format("opentype");
}


.mittelalter {
  font-family: Pfeffer;
  font-size: 1.2em;
}


.App {
width:100%;

}

header {
  background-color: #443857;
  text-align: center;
  height: 4.5rem;
  padding-top: 1rem;
}
header a,
header a.logo:hover {
  text-decoration:none;
}

header i {
  margin-right: 10px;
}

header h1 {
  color:white;
  font-size:2.0rem;
}

h1, h2, h3, h4 {
  font-family: merloround_bold, arial;
  color: #443857;
}

.mittelalter h1,
.mittelalter h2,
.mittelalter h3,
.mittelalter h4 {
  font-family: Pfeffer, arial;
  color: darkred;
}



.recepie-row {
  display: grid;
  grid-template-columns: 30% 20% 15% 15%;
  min-height: 3rem;
  padding-top: 0.5rem;
  border-bottom: 1px dotted orange;
}
.item-list a {
  color: black;
}

.btn-primary {
    color: #fff;
    background-color: #443857;
    border-color: #443857;
    margin-right: 3px;
  }


.Zeitangaben, .Zubereitungsschritte ,.Tools ,.Zutaten {
  margin-bottom: 1rem;
}




/* Tooltip container */
.bidat_tooltip {
}

/* Tooltip text */
.bidat_tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.bidat_tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.bidat_tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


@media (min-width: 800px) {
  header h1 {
    font-size:2.5rem;
  }

}


@media print {
  h1, h2, h3, h4 {
    font-family: merloround_bold, arial;
    color: #0d6d19;
  }
  p {
    margin-bottom: 0.5rem;
  }
}