footer {
  background-color: #443857;
  color: white;
  display:block;
}

footer details {
  width: 95%;
  margin-left: 5%;
}

details em {
  font-style: normal;
  font-weight: bold;
}

details a {
  color: orange;
  font-weight: bold;

}
details a:hover {
  color: lightgrey;
}

@media (min-width: 800px) {
  footer {
    display:flex;
  }

  footer details {
    width: 25%;

  }
}
